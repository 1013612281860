import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserName } from "../../features/components/componentSlice";
import Cookies from "js-cookie";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./auth.css";

axios.defaults.withCredentials = true;

const URL = `${process.env.REACT_APP_BASE_URL}users`;

const initialState = {
  email: "",
  password: "",
  passwordConfirm: "",
  username: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // if (id !== "kelvinkamau") {
    //   return navigate("/notFound", { replace: true });
    // }

    let inviteData;
    let inviteArr = [];

    async function getInvites() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}users/invites`, {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
          withCredentials: true,
        })
        .then(
          (data) => {
            inviteData = data.data.data.invites;

            inviteData.map((a) => inviteArr.push(a.inviteId));

            if (!inviteArr.includes(id)) {
              return navigate("/notFound", { replace: true });
            }
          },
          (error) => {
            error.response
              ? toast.error(error.response.data?.data?.message)
              : toast.error(
                  "Oops! Something went wrong on our end. We sincerely apologize for the inconvenience."
                );
          }
        );
    }

    getInvites();
  }, []);

  const [form, setForm] = useState(initialState);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const HandleSignUp = async (e) => {
    e.preventDefault();

    const { email, password, passwordConfirm, username } = form;

    await axios
      .post(
        `${URL}/signup`,
        {
          email,
          password,
          passwordConfirm,
          username,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      )
      .then(
        (data) => {
          Cookies.set("jwt", data.data.token);
          toast.success("Account created successfully");
          let username = data.data.data.user.username;
          dispatch(setUserName(username));

          // Navigate to the Dashboard route
          navigate("/dashboard");
        },
        (error) => {
          error.response
            ? toast.error(error.response.data?.data?.message)
            : toast.error(
                "Oops! Something went wrong on our end. We sincerely apologize for the inconvenience."
              );
        }
      );
  };
  return (
    <div className="container">
      <Toaster />
      <div className="wrapper">
        <div className="logo">
          <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
        </div>
        <div className="title-text">
          <div className="title login margin-zero">
            <p>SIGN UP</p>
          </div>
        </div>
        <div className="form-container">
          <div className="form-inner">
            <form onSubmit={HandleSignUp} className="signup">
              <div className="field">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <input
                  type="password"
                  placeholder="Confirm password"
                  name="passwordConfirm"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field btn">
                <div className="btn-layer"></div>
                <input type="submit" value="Signup" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Login = () => {
  const [form, setForm] = useState(initialState);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const { email, password } = form;

    await axios
      .post(
        `${URL}/login`,
        {
          email,
          password,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      )
      .then(
        (data) => {
          toast.success("Logged In Successfully");
          Cookies.set("jwt", data.data.token);
          let username = data.data.data.user.username;
          dispatch(setUserName(username));
          // Navigate to the Dashboard route
          navigate("/dashboard");
        },
        (error) => {
          error.response
            ? toast.error(error.response.data?.data?.message)
            : toast.error(
                "Oops! Something went wrong on our end. We sincerely apologize for the inconvenience."
              );
        }
      );
  };
  return (
    <div className="container">
      <Toaster />
      <div className="wrapper">
        <div className="logo">
          <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
        </div>
        <div className="title-text">
          <div className="title login margin-zero">
            <p>LOGIN</p>
          </div>
        </div>
        <div className="form-container">
          <div className="form-inner">
            <form onSubmit={handleLogin}>
              <div className="field">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field btn">
                <div className="btn-layer"></div>
                <input type="submit" value="Subscriber Login" />
              </div>
              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <a
                  href="/resetPassword"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "0.9rem",
                  }}
                >
                  Forgot your password?
                </a>
              </div>
              <div class="field btn">
                <a
                  href="https://test.kamfupi.link/"
                  class="btn-layer"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.2rem",
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  VISITOR LOGIN
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Auth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("jwt");
    if (token) {
      navigate("/dashboard");
    }
  }, []);
  return <Login />;
};

export { SignUp };
export default Auth;

import { useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const initialState = {
  password: "",
  passwordConfirm: "",
};

const ResetForm = () => {
  const [form, setForm] = useState(initialState);
  const navigate = useNavigate();

  const location = useLocation();

  const updateEndpoint = location.pathname.replace(/^\/+/g, "");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleReset = async (e) => {
    e.preventDefault();

    const { password, passwordConfirm } = form;

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}${updateEndpoint}`,
        {
          password,
          passwordConfirm,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      )
      .then(
        () => {
          toast.success("Your password has been updated successfully");

          // Navigate to the Login route
          navigate("/login");
        },
        (error) => {
          error.response
            ? toast.error(error.response.data?.data?.message)
            : toast.error(
                "Oops! Something went wrong on our end. We sincerely apologize for the inconvenience."
              );
        }
      );
  };

  return (
    <div className="container">
      <Toaster />
      <div className="wrapper">
        <div className="logo">
          <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
        </div>
        <div className="title-text">
          <div className="title login margin-zero">
            <p>UPDATE PASSWORD</p>
          </div>
        </div>
        <div className="form-container">
          <div className="form-inner">
            <form onSubmit={handleReset}>
              <div className="field">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <input
                  type="password"
                  placeholder="Confirm password"
                  name="passwordConfirm"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field btn">
                <div className="btn-layer"></div>
                <input type="submit" value="Update" />
              </div>
              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <a
                  href="/login"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "0.9rem",
                  }}
                >
                  Back to login
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetForm;

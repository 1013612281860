import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./components/auth/Auth";
import { SignUp } from "./components/auth/Auth";
import Dashboard from "./components/dashboard/Dashboard";
import Create from "./components/create/Create";
import NotFound from "./components/notFound/NotFound";
import PassReset from "./components/reset/PassReset";
import ResetForm from "./components/reset/ResetForm";
import Landing from "./components/landing/Landing";
import KamfupiCost from "./components/landing/KamfupiCost";
import KamfupiFor from "./components/landing/KamfupiFor";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/kamfupi-for" element={<KamfupiFor />} />
          <Route path="/kamfupi-cost" element={<KamfupiCost />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/signup/:id" element={<SignUp />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/create" element={<Create />} />
          <Route path="/notFound" element={<NotFound />} />
          <Route path="/resetPassword" element={<PassReset />} />
          <Route path="/users/resetPassword/:id" element={<ResetForm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;

import { useState } from "react";
import { SpinnerRoundFilled } from "spinners-react";

import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const URL = `${process.env.REACT_APP_BASE_URL}users`;

const initialState = {
  email: "",
};

const PassReset = () => {
  const [form, setForm] = useState(initialState);
  let [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleReset = async (e) => {
    e.preventDefault();

    const { email } = form;

    await axios
      .post(
        `${URL}/forgotPassword`,
        {
          email,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      )
      .then(
        () => {
          setLoading(false);
          toast.success("The Password Reset link has been sent to your email");
        },
        () => {
          toast.error("There was a problem with the server, kindly try again");
        }
      );
  };

  return (
    <div className="container">
      <Toaster />
      <div className="wrapper">
        <div className="logo">
          <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
        </div>
        <div className="title-text">
          <div className="title login margin-zero">
            <p>RESET PASSWORD</p>
          </div>
        </div>
        <div className="form-container">
          <div className="form-inner">
            <form onSubmit={handleReset}>
              <div className="field">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="field btn">
                <div className="btn-layer"></div>
                <input
                  type="submit"
                  value="Request Reset Link"
                  onClick={() => setLoading(true)}
                />
              </div>

              <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                <a
                  href="/login"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "0.9rem",
                  }}
                >
                  Back to login
                </a>
              </div>
              <SpinnerRoundFilled
                size={47}
                thickness={100}
                speed={100}
                color="#d69a33"
                enabled={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassReset;

import { createSlice } from "@reduxjs/toolkit";

const storedUsername = localStorage.getItem("username"); // Retrieve the username from local storage

const initialState = {
  text: "createUrl",
  shortCodes: [],
  longUrl: "",
  username: storedUsername || "", // Use the stored username if available
};

const componentsSlice = createSlice({
  name: "components",
  initialState,
  reducers: {
    changeComponent: (state, action) => {
      state.text = action.payload;
    },
    changeShortCodes: (state, action) => {
      state.shortCodes = action.payload;
    },
    changeLongUrl: (state, action) => {
      state.longUrl = action.payload;
    },
    setUserName: (state, action) => {
      state.username = action.payload;
      localStorage.setItem("username", action.payload); // Store the updated username in local storage
    },
  },
});

export const { changeComponent, changeShortCodes, changeLongUrl, setUserName } =
  componentsSlice.actions;
export const selectComponent = (state) => state.components;

export default componentsSlice.reducer;

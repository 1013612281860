import React from "react";
import "./404.css";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <p>
            Sorry. So Sorry. Terribly Sorry. We're terribly sorry. The item you
            are looking for cannot be found. Maybe there was an error in the
            URL. Maybe it was removed. We don't have it. Our apologies. So
            sorry.
          </p>

          <h2 style={{ padding: "2% 0 0 0" }}>
            <u>IMPORTANT</u>
          </h2>
          <p style={{ padding: "0 20% 0 20%" }}>
            Please let us know the missing shortlink address, so we can fix the
            problem if it is our fault. Please email{" "}
            <a href="mailto:contact@kamusi.org">contact@kamusi.org</a> with the
            subject "missing shortlink" and the URL you thought you
            were navigating to.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import { useState, forwardRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectComponent,
  changeComponent,
  changeShortCodes,
  changeLongUrl,
} from "../../features/components/componentSlice";

import Cookies from "js-cookie";
import axios from "axios";
import MaterialTable from "material-table";
import jwt_decode from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";

import Create from "../create/Create";
import Update from "../update/Update";
import Share from "../share/Share";
import Invite from "../invite/Invite";
import { Stats } from "../stats/stats";
import NotFound from "../notFound/NotFound";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Search,
  SaveAlt,
  Edit,
  DeleteOutline,
  FirstPage,
  LastPage,
  Remove,
  ViewColumn,
  FilterList,
} from "@material-ui/icons";

import "./dashboard.css";

axios.defaults.withCredentials = true;

const URL = `${process.env.REACT_APP_BASE_URL}u`;
const ALLURL = `${process.env.REACT_APP_BASE_URL}all`;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const components = useSelector(selectComponent);

  const navigate = useNavigate();
  const location = useLocation();

  const component = components.text;
  const updatesToCodes = components.shortCodes;

  const [toggle, setToggle] = useState(false);
  const [theme, setTheme] = useState(true);
  const [urlData, setData] = useState([{}]);
  const [fileData, setFileData] = useState([{}]);
  const [allData, setAllData] = useState([{}]);
  const [userData, setUserData] = useState([{}]);
  const [createInput, setCreateInput] = useState("urls");
  const [extension, setExtension] = useState("");
  const [shareUrls, setShareUrls] = useState([]);
  const [srcUrl, setSrcUrl] = useState(null);
  const [codeSlug, setCodeSlug] = useState("");
  const [statsArray, setStatsArray] = useState([]);
  const [statsMbs, setStatsMbs] = useState([]);
  const [userRole, setUserRole] = useState({});
  const [userStatus, setUserStatus] = useState(true);

  let allDataColumnHeaders = [
    {
      title: "Share Link",
      field: "button",
      type: "string",
      editable: "never",
      sorting: false,
      filtering: false,
    },
    {
      title: "Short Codes",
      field: "urlCodes",
    },

    {
      title: "Modified On",
      field: "createdAt",
      type: "date",
      editable: "never",
    },
    {
      title: "Created By",
      field: "createdBy",
      type: "string",
      editable: "never",
    },
    {
      title: "Update Link",
      field: "updateButton",
      sorting: false,
      type: "string",
      editable: "never",
      filtering: false,
    },
    {
      title: "Views",
      field: "visits",
      type: "numeric",
      editable: "never",
      filtering: false,
    },
  ];

  let urlColumnHeaders = [
    {
      title: "Share Link",
      field: "button",
      type: "string",
      editable: "never",
      sorting: false,
      filtering: false,
    },
    {
      title: "Short Codes",
      field: "urlCodes",
    },
    {
      title: "Long Url",
      field: "longUrl",
      type: "string",
    },

    {
      title: "Modified On",
      field: "createdAt",
      type: "date",
      editable: "never",
    },
    {
      title: "Created By",
      field: "createdBy",
      type: "string",
      editable: "never",
    },
    {
      title: "Update Link",
      field: "updateButton",
      sorting: false,
      type: "string",
      editable: "never",
      filtering: false,
    },
    {
      title: "Views",
      field: "visits",
      type: "numeric",
      editable: "never",
      filtering: false,
    },
  ];
  let imageColumnHeaders = [
    {
      title: "Share Link",
      field: "button",
      type: "string",
      sorting: false,
      editable: "never",
      filtering: false,
    },
    {
      title: "Image",
      field: "image",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Short Codes",
      field: "urlCodes",
    },
    {
      title: "File Name",
      field: "fileName",
      type: "string",
    },

    {
      title: "Modified On",
      field: "createdAt",
      type: "date",
      editable: "never",
    },
    {
      title: "Created By",
      field: "createdBy",
      type: "string",
      editable: "never",
    },
    {
      title: "Update Short Codes",
      field: "updateButton",
      sorting: false,
      type: "string",
      editable: "never",
      filtering: false,
    },
    {
      title: "Duration",
      field: "duration",
      type: "string",
      editable: "never",
    },
    {
      title: "Views",
      field: "visits",
      type: "numeric",
      editable: "never",
      filtering: false,
    },
  ];

  let fileColumnHeaders = [
    {
      title: "Share Link",
      field: "button",
      sorting: false,
      type: "string",
      editable: "never",
      filtering: false,
    },
    {
      title: "Short Codes",
      field: "urlCodes",
    },
    {
      title: "File Name",
      field: "fileName",
      type: "string",
    },

    {
      title: "Modified On",
      field: "createdAt",
      type: "date",
      editable: "never",
    },
    {
      title: "Created By",
      field: "createdBy",
      type: "string",
      editable: "never",
    },
    {
      title: "Update Short Codes",
      field: "updateButton",
      sorting: false,
      type: "string",
      editable: "never",
      filtering: false,
    },
    {
      title: "Duration",
      field: "duration",
      type: "string",
      editable: "never",
    },
    {
      title: "Views",
      field: "visits",
      type: "numeric",
      editable: "never",
      filtering: false,
    },
  ];

  let userColumnHeaders = [
    {
      title: "Username",
      field: "username",
      type: "string",
      editable: "never",
    },
    {
      title: "Server Space",
      field: "serverSpace",
      type: "string",
      editable: "never",
      filtering: false,
    },

    {
      title: "Account Created On",
      field: "createdOn",
      type: "date",
    },
    {
      title: "Active",
      field: "active",
      type: "string",
      editable: "never",
    },
    {
      title: "Activate/Deactivate User",
      field: "button",
      type: "string",
      editable: "never",
      sorting: false,
      filtering: false,
    },
  ];

  const dataArray = [];

  useEffect(() => {
    const token = Cookies.get("jwt");

    setUserRole(jwt_decode(token));

    if (!token) {
      navigate("/login", { replace: true });
    }
  }, []);

  useEffect(() => {
    location.pathname === "/dashboard/all" &&
      dispatch(changeComponent("getAllData"));
    location.pathname === "/dashboard/urls" &&
      dispatch(changeComponent("getUrls"));
    location.pathname === "/dashboard/documents" &&
      dispatch(changeComponent("getDocuments"));
    location.pathname === "/dashboard/images" &&
      dispatch(changeComponent("getImages"));
    location.pathname === "/dashboard/audio" &&
      dispatch(changeComponent("getAudio"));
    location.pathname === "/dashboard/videos" &&
      dispatch(changeComponent("getVideos"));
    location.pathname === "/dashboard/compressed" &&
      dispatch(changeComponent("getCompressed"));
    location.pathname === "/dashboard/usage-data" &&
      dispatch(changeComponent("getStats"));
    location.pathname === "/dashboard/users" &&
      dispatch(changeComponent("userStats"));
    location.pathname === "/dashboard/invite" &&
      dispatch(changeComponent("invite"));
  }, [location.pathname, dispatch]);

  useEffect(() => {
    component === "getAllData" && getAllData();
    component === "getUrls" && getData();
    component === "getDocuments" && getDocuments();
    component === "getImages" && getImages();
    component === "getAudio" && getAudio();
    component === "getVideos" && getVideos();
    component === "getCompressed" && getCompressed();
    component === "getStats" && getStats();
    component === "userStats" && getUserStats();
  }, [component, userStatus]);

  if (userRole.role === "user") {
    urlColumnHeaders = urlColumnHeaders.filter(
      (el) => el.field !== "createdBy"
    );
    imageColumnHeaders = imageColumnHeaders.filter(
      (el) => el.field !== "createdBy"
    );
    fileColumnHeaders = fileColumnHeaders.filter(
      (el) => el.field !== "createdBy"
    );
    allDataColumnHeaders = allDataColumnHeaders.filter(
      (el) => el.field !== "createdBy"
    );
  }

  const changeToggle = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  const changeTheme = () => {
    theme ? setTheme(false) : setTheme(true);
  };

  const getStats = async () => {
    const urlData = await axios.get(`${process.env.REACT_APP_BASE_URL}u`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    const documentData = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/documents`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const imageData = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/images`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const videoData = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/videos`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const audioData = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/audios`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const compressedData = await axios.get(
      `${process.env.REACT_APP_BASE_URL}c/files`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    setStatsArray([
      urlData.data.data.urls.length,
      documentData.data.data.files.length,
      imageData.data.data.files.length,
      videoData.data.data.files.length,
      audioData.data.data.files.length,
      compressedData.data.data.files.length,
    ]);

    setStatsMbs([
      0,
      documentData.data.data.files
        .map((el) => el.size)
        .reduce((a, b) => a + b, 0) / (1048576).toFixed(1),
      imageData.data.data.files
        .map((el) => el.size)
        .reduce((a, b) => a + b, 0) / (1048576).toFixed(1),
      videoData.data.data.files
        .map((el) => el.size)
        .reduce((a, b) => a + b, 0) / (1048576).toFixed(1),
      audioData.data.data.files
        .map((el) => el.size)
        .reduce((a, b) => a + b, 0) / (1048576).toFixed(1),
      compressedData.data.data.files
        .map((el) => el.size)
        .reduce((a, b) => a + b, 0) / (1048576).toFixed(1),
    ]);
  };

  const statsData = {
    labels: [
      "Urls",
      "Documents",
      "Images",
      "Videos",
      "Audio",
      "Compressed Files",
    ],
    datasets: [
      {
        label: "Size in MBs",
        data: statsMbs.map((el) => el.toFixed(1)),
        backgroundColor: ["rgb(0, 99, 32)"],
        hoverOffset: 4,
      },
    ],
  };

  const itemCount = {
    labels: [
      "Urls",
      "Documents",
      "Images",
      "Videos",
      "Audio",
      "Compressed Files",
    ],
    datasets: [
      {
        label: "Number Of Items",
        data: statsArray,
        backgroundColor: ["rgb(214, 154, 51)"],
        hoverOffset: 4,
      },
    ],
  };

  const getAllData = async () => {
    navigate("/dashboard/all");
    const allData = await axios.get(`${ALLURL}`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    const dataUrls = await axios.get(`${URL}`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    const dataDocuments = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/documents`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const dataImages = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/images`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const dataVideos = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/videos`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const dataAudio = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/audios`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    const dataCompressed = await axios.get(
      `${process.env.REACT_APP_BASE_URL}c/files`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    function doesObjectExistInArray(obj, array, compareFn) {
      for (const element of array) {
        if (compareFn(obj, element)) {
          return true;
        }
      }
      return false;
    }

    const customCompareFn = (obj1, obj2) => {
      return obj2.id === obj1.id;
    };

    allData.data.data.data.forEach((url) => {
      for (let i = url.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = url.visits;
        dataArray.push({
          tag: url.urlCodes[i],
          urlCodes: url.urlCodes[i],
          visits: urlVisits[url.urlCodes[i]],
          createdBy: url?.createdBy?.username,
          createdAt: new Date(url.modifiedOn).toDateString(),
          shortUrls: url.shortUrls[i],
          modelName: url.modelName,
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(url.shortUrls[i]);
                setSrcUrl(null);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateAudio");
                doesObjectExistInArray(
                  url,
                  dataUrls.data.data.urls,
                  customCompareFn
                ) &&
                  dispatch(changeComponent("updateUrls")) &&
                  dispatch(changeLongUrl(url.longUrl));

                doesObjectExistInArray(
                  url,
                  dataDocuments.data.data.files,
                  customCompareFn
                ) && dispatch(changeComponent("updateDocument"));

                doesObjectExistInArray(
                  url,
                  dataImages.data.data.files,
                  customCompareFn
                ) && dispatch(changeComponent("updateImage"));

                doesObjectExistInArray(
                  url,
                  dataAudio.data.data.files,
                  customCompareFn
                ) && dispatch(changeComponent("updateAudio"));

                doesObjectExistInArray(
                  url,
                  dataVideos.data.data.files,
                  customCompareFn
                ) && dispatch(changeComponent("updateVideo"));

                doesObjectExistInArray(
                  url,
                  dataCompressed.data.data.files,
                  customCompareFn
                ) && dispatch(changeComponent("updateCompressed"));

                dispatch(changeShortCodes(url.urlCodes));

                setCodeSlug(url.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setAllData(dataArray);
  };

  const getData = async () => {
    navigate("/dashboard/urls");
    const dataUrls = await axios.get(`${URL}`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    dataUrls.data.data.urls.forEach((url) => {
      for (let i = url.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = url.visits;
        dataArray.push({
          longUrl: `${url.longUrl.substring(0, 20)}...${url.longUrl.substring(
            21,
            25
          )}`,
          tag: url.urlCodes[i],
          urlCodes: url.urlCodes[i],
          visits: urlVisits[url.urlCodes[i]],
          createdBy: url?.createdBy?.username,
          createdAt: new Date(url.modifiedOn).toDateString(),
          shortUrls: url.shortUrls[i],
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(url.shortUrls[i]);
                setSrcUrl(null);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateUrls");
                dispatch(changeComponent("updateUrls"));
                dispatch(changeLongUrl(url.longUrl));
                dispatch(changeShortCodes(url.urlCodes));
                setCodeSlug(url.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setData(dataArray);
  };

  const getDocuments = async () => {
    navigate("/dashboard/documents");
    const dataDocuments = await axios.get(
      `${process.env.REACT_APP_BASE_URL}f/documents`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    dataDocuments.data.data.files.forEach((file) => {
      for (let i = file.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = file.visits;
        dataArray.push({
          fileName: file.fileName,
          tag: file.urlCodes[i],
          urlCodes: file.urlCodes[i],
          visits: urlVisits[file.urlCodes[i]],
          duration: file.duration,
          createdBy: file.createdBy.username,
          createdAt: new Date(file.modifiedOn).toDateString(),
          shortUrls: file.shortUrls[i],
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(file.shortUrls[i]);
                setSrcUrl(file.srcUrl);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateDocument");
                dispatch(changeComponent("updateDocument"));
                dispatch(changeShortCodes(file.urlCodes));
                setCodeSlug(file.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setFileData(dataArray);
  };

  const getImages = async () => {
    navigate("/dashboard/images");
    const data = await axios.get(`${process.env.REACT_APP_BASE_URL}f/images`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    data.data.data.files.forEach((file) => {
      for (let i = file.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = file.visits;
        dataArray.push({
          fileName: file.fileName,
          tag: file.urlCodes[i],
          urlCodes: file.urlCodes[i],
          visits: urlVisits[file.urlCodes[i]],
          image: (
            <div
              style={{
                objectFit: "cover",
                margin: 0,
                height: "40px",
                width: "40px",
              }}
            >
              <img
                src={file.srcUrl}
                alt={file.fileName}
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "2px",
                }}
              />
            </div>
          ),
          duration: file.duration,
          createdBy: file.createdBy.username,
          createdAt: new Date(file.modifiedOn).toDateString(),
          shortUrls: file.shortUrls[i],
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(file.shortUrls[i]);
                setSrcUrl(`<img src="${file.srcUrl}" alt="${file.fileName}">`);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateImage");
                dispatch(changeComponent("updateImage"));
                dispatch(changeShortCodes(file.urlCodes));

                setCodeSlug(file.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setFileData(dataArray);
  };

  const getVideos = async () => {
    navigate("/dashboard/videos");
    const data = await axios.get(`${process.env.REACT_APP_BASE_URL}f/videos`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    data.data.data.files.forEach((file) => {
      for (let i = file.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = file.visits;
        dataArray.push({
          fileName: file.fileName,
          tag: file.urlCodes[i],
          urlCodes: file.urlCodes[i],
          visits: urlVisits[file.urlCodes[i]],
          duration: file.duration,
          createdBy: file.createdBy.username,
          createdAt: new Date(file.modifiedOn).toDateString(),
          shortUrls: file.shortUrls[i],
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(file.shortUrls[i]);
                setSrcUrl(`<video controls>
  <source src="${file.srcUrl}">
</video>`);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateVideo");
                dispatch(changeComponent("updateVideo"));
                dispatch(changeShortCodes(file.urlCodes));

                setCodeSlug(file.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setFileData(dataArray);
  };

  const getAudio = async () => {
    navigate("/dashboard/audio");
    const data = await axios.get(`${process.env.REACT_APP_BASE_URL}f/audios`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    data.data.data.files.forEach((file) => {
      for (let i = file.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = file.visits;
        dataArray.push({
          fileName: file.fileName,
          tag: file.urlCodes[i],
          urlCodes: file.urlCodes[i],
          visits: urlVisits[file.urlCodes[i]],
          duration: file.duration,
          createdBy: file.createdBy.username,
          createdAt: new Date(file.modifiedOn).toDateString(),
          shortUrls: file.shortUrls[i],
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(file.shortUrls[i]);
                setSrcUrl(`<audio controls>
  <source src="${file.srcUrl}">
</audio>`);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateAudio");
                dispatch(changeComponent("updateAudio"));
                dispatch(changeShortCodes(file.urlCodes));

                setCodeSlug(file.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setFileData(dataArray);
  };

  const getCompressed = async () => {
    navigate("/dashboard/compressed");
    const data = await axios.get(`${process.env.REACT_APP_BASE_URL}c/files`, {
      headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
      withCredentials: true,
    });

    data.data.data.files.forEach((file) => {
      for (let i = file.urlCodes.length - 1; i >= 0; i--) {
        let urlVisits = file.visits;
        dataArray.push({
          fileName: file.fileName,
          tag: file.urlCodes[i],
          urlCodes: file.urlCodes[i],
          visits: urlVisits[file.urlCodes[i]],
          duration: file.duration,
          createdBy: file.createdBy.username,
          createdAt: new Date(file.modifiedOn).toDateString(),
          shortUrls: file.shortUrls[i],
          button: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("shareUrls");
                dispatch(changeComponent("shareUrls"));
                setShareUrls(file.shortUrls[i]);
                setSrcUrl(file.srcUrl);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              share
            </button>
          ),
          updateButton: (
            <button
              className="share-button"
              onClick={() => {
                // setComponent("updateCompressed");
                dispatch(changeComponent("updateCompressed"));
                dispatch(changeShortCodes(file.urlCodes));

                setCodeSlug(file.urlCodes[0]);
              }}
              style={{ padding: "0.8px 7px" }}
            >
              Update
            </button>
          ),
        });
      }
    });
    setFileData(dataArray);
  };

  const getUserStats = async () => {
    navigate("/dashboard/users");
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}users/stats`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        withCredentials: true,
      }
    );

    data.data.data.serverSpace.forEach((user) => {
      dataArray.push({
        username: user.username,
        serverSpace:
          user.serverSpace / 1000 < 1024
            ? (user.serverSpace / 1000).toFixed(1) + "KB"
            : (user.serverSpace / (1000 * 1000)).toFixed(1) + "MB",
        createdOn: new Date(user.accountCreatedOn).toDateString(),
        active: user.active,
        button: (
          <button
            className="share-button"
            onClick={async () => {
              await axios
                .patch(
                  `${process.env.REACT_APP_BASE_URL}users/stats/${user._id}`,
                  {
                    active: !user.active,
                  },
                  {
                    headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
                  }
                )
                .then(
                  () => {
                    setUserStatus(!userStatus);
                    toast.success("User status updated successfully");
                  },
                  () => {
                    toast.error(`Server Error`);
                  }
                );
              dispatch(changeComponent("userStats"));
            }}
            style={{ padding: "0.8px 7px" }}
          >
            {!user.active ? "Activate User" : "Deactivate User"}
          </button>
        ),
      });
    });
    setUserData(dataArray);
  };

  const logout = () => {
    <Toaster />;
    Cookies.remove("jwt");
    navigate("/", { replace: true });
    toast.success("Logged out successfully");
  };

  return (
    <div className={theme ? "dash-container" : "dash-container dark"}>
      <Toaster position="top-right" reverseOrder={false} />
      <nav className={toggle ? "sidebar close" : "sidebar"}>
        <header>
          <div className="image-text">
            <span className="image">
              <img src={"https://kamu.si/kamfupi-logo"} alt="logo" />
            </span>

            <div className="text logo-text">
              <span className="name">Kamfupi</span>
              <span className="profession">by Kamusi Labs</span>
            </div>
          </div>

          <i className="bx bxs-chevron-right toggle" onClick={changeToggle}></i>
        </header>

        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              <li
                className="nav-link dashboard"
                onClick={() => {
                  // setComponent("createUrl");
                  dispatch(changeComponent("createUrl"));
                  navigate("/dashboard/shorten");
                }}
              >
                <button>
                  <i className="bx bx-plus icon"></i>
                  <span className="text nav-text"> Create new item</span>
                </button>
              </li>

              <li
                className="nav-link"
                onClick={() => {
                  dispatch(changeComponent("getAllData"));
                }}
              >
                <button onClick={getAllData}>
                  <i className="bx bx-search icon"></i>
                  <span className="text nav-text">Search all items</span>
                </button>
              </li>

              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("getUrls");
                  dispatch(changeComponent("getUrls"));
                }}
              >
                <button onClick={getData}>
                  <i className="bx bx-link-alt icon"></i>
                  <span className="text nav-text">Urls</span>
                </button>
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("getDocuments");
                  dispatch(changeComponent("getDocuments"));
                }}
              >
                <button onClick={getDocuments}>
                  <i className="bx bxs-book-content icon"></i>
                  <span className="text nav-text">Documents</span>
                </button>
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("getImages");
                  dispatch(changeComponent("getImages"));
                }}
              >
                <button onClick={getImages}>
                  <i className="bx bxs-image icon"></i>
                  <span className="text nav-text">Images</span>
                </button>
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("getVideos");
                  dispatch(changeComponent("getVideos"));
                }}
              >
                <button onClick={getVideos}>
                  <i className="bx bxs-video-recording icon"></i>
                  <span className="text nav-text">Videos</span>
                </button>
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("getAudio");
                  dispatch(changeComponent("getAudio"));
                }}
              >
                <button onClick={getAudio}>
                  <i class="bx bxs-music icon"></i>
                  <span className="text nav-text">Audio</span>
                </button>
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("getCompressed");
                  dispatch(changeComponent("getCompressed"));
                }}
              >
                <button onClick={getCompressed}>
                  <i className="bx bxs-file-archive icon"></i>
                  <span className="text nav-text">Compressed files</span>
                </button>
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  // setComponent("stats");
                  dispatch(changeComponent("getStats"));
                  navigate("usage-data");
                }}
              >
                <button onClick={getStats}>
                  <i className="bx bxs-data icon"></i>
                  <span className="text nav-text">Usage data</span>
                </button>
              </li>

              {userRole.role === "admin" && (
                <li
                  className="nav-link"
                  onClick={() => {
                    dispatch(changeComponent("userStats"));
                    navigate("/dashboard/users");
                  }}
                >
                  <button onClick={getUserStats}>
                    <i className="bx bxs-user-account icon"></i>
                    <span className="text nav-text">Manage users</span>
                  </button>
                </li>
              )}

              {userRole.role === "admin" && (
                <li
                  className="nav-link"
                  onClick={() => {
                    dispatch(changeComponent("invite"));
                    navigate("/dashboard/invite");
                  }}
                >
                  <button>
                    <i className="bx bx-link icon"></i>
                    <span className="text nav-text">Invite link</span>
                  </button>
                </li>
              )}
              <li className="nav-link">
                <button>
                  <i className="bx bxs-message-square-detail icon"></i>
                  <a
                    href="http://kamu.si/kamfupi-faq"
                    style={{ textDecoration: "none", color: "#2e2e2e" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text nav-text">About Kamfupi</span>
                  </a>
                </button>
              </li>
              <li className="nav-link">
                <button>
                  <i className="bx bxs-graduation icon"></i>
                  <a
                    href="https://test.kamfupi.link/"
                    style={{ textDecoration: "none", color: "#2e2e2e" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text nav-text">Tutorial</span>
                  </a>
                </button>
              </li>
            </ul>
          </div>

          <div className="bottom-content">
            <li className="nav-link">
              <button>
                <i class="bx bxs-user icon"></i>
                <span className="text nav-text">{components.username}</span>
              </button>
            </li>

            <li className="nav-link">
              <button onClick={logout}>
                <i className="bx bx-log-out icon"></i>
                <span className="text nav-text">Logout</span>
              </button>
            </li>

            <li className="mode">
              <div className="sun-moon">
                <i className="bx bxs-moon icon moon"></i>
                <i className="bx bxs-sun icon sun"></i>
              </div>
              <span className="mode-text text">
                {theme ? "Light mode" : "Dark mode"}
              </span>

              <div className="toggle-switch" onClick={changeTheme}>
                <span className="switch"></span>
              </div>
            </li>
          </div>
        </div>
      </nav>
      <section className="home">
        {component === "getAllData" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>All Items</h3>
                  <span>&nbsp;&nbsp;</span>
                </div>
              }
              columns={allDataColumnHeaders}
              data={allData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${
                            (oldData.modelName === "Url" && URL) ||
                            (oldData.modelName === "File" &&
                              process.env.REACT_APP_BASE_URL + "f") ||
                            (oldData.modelName === "Compressed" &&
                              process.env.REACT_APP_BASE_URL + "c")
                          }/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getData();
                          let filtered = urlData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setData(filtered);
                          // setComponent("getUrls");

                          dispatch(changeComponent("getAllData"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}
        {component === "getUrls" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>URLS</h3>
                  <span>&nbsp;&nbsp;</span>
                  <i
                    className="bx bx-plus add"
                    onClick={() => {
                      // setComponent("createUrl");
                      dispatch(changeComponent("createUrl"));
                      setCreateInput("urls");
                      navigate("/dashboard/shorten/long-url");
                    }}
                  ></i>
                </div>
              }
              columns={urlColumnHeaders}
              data={urlData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${URL}/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getData();
                          let filtered = urlData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setData(filtered);
                          // setComponent("getUrls");

                          dispatch(changeComponent("getUrls"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}
        {component === "createUrl" && (
          <Create createInput={createInput} extension={extension} />
        )}
        {component === "shareUrls" && (
          <div>
            <Share shortenedUrl={shareUrls} srcUrl={srcUrl} />
          </div>
        )}
        {component === "updateUrls" && (
          <div>
            <Update
              createInput={"urls"}
              shortCodeUpdates={updatesToCodes}
              urlParams={codeSlug}
            />
          </div>
        )}

        {component === "updateDocument" && (
          <div>
            <Update
              createInput={"documents"}
              extension={".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"}
              shortCodeUpdates={updatesToCodes}
              urlParams={codeSlug}
            />
          </div>
        )}

        {component === "updateImage" && (
          <div>
            <Update
              createInput={"images"}
              extension={"image/*"}
              shortCodeUpdates={updatesToCodes}
              urlParams={codeSlug}
            />
          </div>
        )}

        {component === "updateAudio" && (
          <div>
            <Update
              createInput={"audios"}
              extension={".aac, .midi, .mp3, .ogg, .wav, .m4a"}
              shortCodeUpdates={updatesToCodes}
              urlParams={codeSlug}
            />
          </div>
        )}

        {component === "updateVideo" && (
          <div>
            <Update
              createInput={"videos"}
              extension={"video/*, .mkv"}
              shortCodeUpdates={updatesToCodes}
              urlParams={codeSlug}
            />
          </div>
        )}

        {component === "updateCompressed" && (
          <div>
            <Update
              createInput={"compressed"}
              extension={".zip"}
              shortCodeUpdates={updatesToCodes}
              urlParams={codeSlug}
            />
          </div>
        )}
        {component === "getStats" && (
          <div>
            <Stats data={statsData} />
            <hr />
            <Stats data={itemCount} />
          </div>
        )}
        {component === "notFound" && (
          <div>
            <NotFound />
          </div>
        )}
        {component === "invite" && (
          <div>
            <Invite />
          </div>
        )}

        {component === "getImages" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>IMAGES</h3>
                  <span>&nbsp;&nbsp;</span>
                  <i
                    className="bx bx-plus add"
                    onClick={() => {
                      // setComponent("createUrl");
                      dispatch(changeComponent("createUrl"));
                      setExtension("image/*");
                      setCreateInput("images");
                      navigate("/dashboard/shorten/image");
                    }}
                  ></i>
                </div>
              }
              columns={imageColumnHeaders}
              data={fileData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${process.env.REACT_APP_BASE_URL}f/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getImages();
                          let filtered = fileData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setFileData(filtered);
                          // setComponent("getImages");
                          dispatch(changeComponent("getImages"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}
        {component === "getDocuments" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>DOCUMENTS</h3>
                  <span>&nbsp;&nbsp;</span>
                  <i
                    className="bx bx-plus add"
                    onClick={() => {
                      // setComponent("createUrl");
                      dispatch(changeComponent("createUrl"));
                      setExtension(
                        ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      );
                      setCreateInput("documents");
                      navigate("/dashboard/shorten/document");
                    }}
                  ></i>
                </div>
              }
              columns={fileColumnHeaders}
              data={fileData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${process.env.REACT_APP_BASE_URL}f/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getDocuments();
                          let filtered = fileData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setFileData(filtered);
                          // setComponent("getDocuments");
                          dispatch(changeComponent("getDocuments"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}

        {component === "getAudio" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                  s
                >
                  <h3>AUDIO</h3>
                  <span>&nbsp;&nbsp;</span>
                  <i
                    className="bx bx-plus add"
                    onClick={() => {
                      // setComponent("createUrl");
                      dispatch(changeComponent("createUrl"));
                      setExtension(".aac, .midi, .mp3, .ogg, .wav, .m4a");
                      setCreateInput("audios");
                      navigate("/dashboard/shorten/audio");
                    }}
                  ></i>
                </div>
              }
              columns={fileColumnHeaders}
              data={fileData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${process.env.REACT_APP_BASE_URL}f/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getAudio();
                          let filtered = fileData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setFileData(filtered);
                          getAudio();
                          // setComponent("getVideos");
                          dispatch(changeComponent("getAudio"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}

        {component === "getVideos" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>VIDEOS</h3>
                  <span>&nbsp;&nbsp;</span>
                  <i
                    className="bx bx-plus add"
                    onClick={() => {
                      // setComponent("createUrl");
                      dispatch(changeComponent("createUrl"));
                      setExtension("video/*, .mkv");
                      setCreateInput("videos");
                      navigate("/dashboard/shorten/video");
                    }}
                  ></i>
                </div>
              }
              columns={fileColumnHeaders}
              data={fileData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${process.env.REACT_APP_BASE_URL}f/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getVideos();
                          let filtered = fileData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setFileData(filtered);
                          getVideos();
                          // setComponent("getVideos");
                          dispatch(changeComponent("getVideos"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}

        {component === "getCompressed" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>COMPRESSED FILES</h3>
                  <span>&nbsp;&nbsp;</span>
                  <i
                    className="bx bx-plus add"
                    onClick={() => {
                      // setComponent("createUrl");
                      dispatch(changeComponent("createUrl"));
                      setExtension(".zip");
                      setCreateInput("compressed");
                      navigate("/dashboard/shorten/compressed");
                    }}
                  ></i>
                </div>
              }
              columns={fileColumnHeaders}
              data={fileData}
              localization={{
                body: {
                  editRow: {
                    deleteText:
                      "Are you sure you want to delete this short code? Click ✓ to delete or X to cancel.",
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      axios
                        .delete(
                          `${process.env.REACT_APP_BASE_URL}c/${oldData.tag}`,
                          {
                            headers: {
                              Authorization: `Bearer ${Cookies.get("jwt")}`,
                            },
                          },
                          {
                            oldData,
                          }
                        )
                        .then(() => {
                          getCompressed();
                          let filtered = fileData.filter(function (
                            value,
                            index,
                            arr
                          ) {
                            return value.shortUrls !== oldData.shortUrls;
                          });

                          setFileData(filtered);
                          // setComponent("getCompressed");
                          dispatch(changeComponent("getCompressed"));
                        });

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}

        {component === "userStats" && (
          <div className="data">
            <MaterialTable
              icons={tableIcons}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <h3>USERS</h3>
                </div>
              }
              columns={userColumnHeaders}
              data={userData}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                search: true,
                paging: true,
                filtering: true,
                pageSize: 20,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return {
                      backgroundColor: "var(--cell-color)",
                      Color: "#fff",
                    };
                  }
                },
                headerStyle: {
                  backgroundColor: "var(--side-bar-color)",
                  color: "var(--text-color)",
                },
              }}
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default Dashboard;

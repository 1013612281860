import React from "react";

const KamfupiFor = () => {
  return (
    <div>
      <iframe
        src="/kamfupi-for.html"
        title="Landing Page"
        width="100%"
        height="500px"
        style={{ width: "100%", height: "100vh", border: "none" }}
      />
    </div>
  );
};

export default KamfupiFor;

import { useState } from "react";
import Clipboard from "react-clipboard.js";
import "./share.css";
import toast from "react-hot-toast";

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  function addHttps(url) {
    // Check if the URL starts with "http://" and replace it with "https://"
    if (/^http:\/\//.test(url)) {
      return url.replace(/^http:\/\//, "https://");
    }
    // If it doesn't start with "https://", add it to the beginning of the URL
    else if (!/^https:\/\//.test(url)) {
      return "https://" + url;
    } else {
      return url;
    }
  }

  return (
    <>
      <div className="field">
        <i className="bx bx-link-alt icon"></i>
        <input type="text" value={copyText} readOnly />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <Clipboard
          data-clipboard-text={copyText}
          className="field share-button"
          onClick={handleCopyClick}
          style={{ height: "35px", outline: "none", border: "none" }}
        >
          <span>{isCopied ? "Copied!" : "Copy Link"}</span>
        </Clipboard>
        <button
          className="share-button"
          style={{ height: "35px", marginTop: "16px" }}
        >
          <a
            href={addHttps(copyText)}
            style={{ textDecoration: "none", color: "#2e2e2e" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit
          </a>
        </button>
      </div>
    </>
  );
}

function WithoutVisit({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <>
      <div className="field">
        <i className="bx bx-link-alt icon"></i>
        <input type="text" value={copyText} readOnly />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <Clipboard
          data-clipboard-text={copyText}
          className="field share-button"
          onClick={handleCopyClick}
          style={{ height: "35px", outline: "none", border: "none" }}
        >
          <span>{isCopied ? "Copied!" : "Copy Link"}</span>
        </Clipboard>
      </div>
    </>
  );
}

function removeHttp(url) {
  return url.replace(/^https?:\/\//, "");
}

function replaceHttpsWithHttp(url) {
  return url.replace(/^https:\/\//, "http://");
}

const Share = ({ shortenedUrl, srcUrl }) => {
  return (
    <div className="create-container" style={{ flexDirection: "column" }}>
      <div className="create-wrapper" style={{ marginBottom: "10px" }}>
        <p>With http</p>
        <ClipboardCopy copyText={replaceHttpsWithHttp(shortenedUrl)} />
      </div>
      <div className="create-wrapper" style={{ marginBottom: "10px" }}>
        <p>Without http</p>
        <ClipboardCopy copyText={removeHttp(shortenedUrl)} />
      </div>

      {srcUrl && (
        <div className="create-wrapper">
          <p>Source Path</p>
          <WithoutVisit copyText={srcUrl} />
        </div>
      )}
    </div>
  );
};
export default Share;

import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get("jwt");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div>
      <iframe
        src="/landing.html"
        title="Landing Page"
        width="100%"
        height="500px"
        style={{ width: "100%", height: "100vh", border: "none" }}
      />
    </div>
  );
};

export default Landing;
